<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
  },

  data() {
    return {
      process: false,
      searchQuery:"",
      modalOk: false,
      tagsInfos: {
        titre: "",
      },
      tags: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      title: "Liste des Tags",
      items: [
        {
          text: "Outils",
          // href: "/projects/tags",
        },
        {
          text: "Tags",
          active: true,
        },
      ],
    };
  },
  computed:{
    filteredUsersList() {
      if (!this.searchQuery) {
        return this.tags;
      }
      return this.tags.filter((tag) => {
        const fullName = tag.name ;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },

    imageCategorie(event) {
      this.imageSlide = event.target.files[0];
    },

    deleteTags(id) {
      this.process = true;
      this.modalOk = false;

      Api.delete("/streamvod/rest/tags/delete-tags/" + id, {
        name: this.tagsInfos.titre,
      })
        .then(() => {
          this.process = false;
          Swal.fire("Success!", "Tag ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createTags() {
      this.process = true;
      this.modalOk = false;

      Api.postFormData("/streamvod/rest/tags/create-tags", {
        name: this.tagsInfos.titre,
      })
        .then(() => {
          this.process = false;
          Swal.fire("Success!", "Tag ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    Api.get("/streamvod/rest/tags/all")
      .then((res) => {
        this.tags = res.data.content;
      })
      .catch((error) => {
        this.process = false;
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-6">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher un tag"
            style="border-radius: 8px"
            v-model="searchQuery"
          />
        </div>
      </div>
      <div class="col-lg-5"></div>
      <div class="col-lg-1" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          @click="showModal"
        >
          {{ $t("addButton") }}
        </button>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-lg-3" v-for="tag in filteredUsersList.slice().reverse()" :key="tag.id">
        <div class="tag-view c2play-primary">
          <span @click="deleteTags(tag.id)">X</span>
          <div class="div">
            {{ tag.name }}
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modalOk"
      id="modal-ml"
      size="ml"
      :title="$t('pages.tags.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label">{{
              $t("pages.tags.formulaire.titre.label")
            }}</label>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="tagsInfos.titre"
                placeholder="Entrez le nom du tag"
              />
            </div>
          </div>
          
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              style="position: relative; left: 30%"
              @click="createTags"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <loaderProcess v-if="process == true"></loaderProcess>
    <!-- end row -->
  </Layout>
</template>

<style>
</style>